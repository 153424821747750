
require('../../components/atoms/disclosure/disclosure.js')
require('../../components/atoms/dropdown-filter/dropdown-filter.js')
require('../../components/atoms/picture/picture.js')
require('../../components/atoms/scroll-down-button/scroll-down-button.js')
require('../../components/atoms/share-button/share-button.js')
require('../../components/atoms/video-embed/video-embed.js')
require('../../components/atoms/video/video.js')
require('../../components/molecules/audio/audio.js')
require('../../components/molecules/detail-image/detail-image.js')
require('../../components/molecules/filters/filters.js')
require('../../components/molecules/form/form.js')
require('../../components/molecules/live-search/live-search.js')
require('../../components/organisms/article-cookies/article-cookies.js')
require('../../components/organisms/article-detail/article-detail.js')
require('../../components/organisms/article-photo-carousel/article-photo-carousel.js')
require('../../components/organisms/article-photos-and-text/article-photos-and-text.js')
require('../../components/organisms/article-video-embed/article-video-embed.js')
require('../../components/organisms/card-carousel/card-carousel.js')
require('../../components/organisms/context-cards-overlay/context-cards-overlay.js')
require('../../components/organisms/image-header/image-header.js')
require('../../components/organisms/lightbox-carousel/lightbox-carousel.js')
require('../../components/organisms/lightbox/lightbox.js')
require('../../components/organisms/my-collection/my-collection.js')
require('../../components/organisms/object-details/object-details.js')
require('../../components/organisms/object-series/object-series.js')
require('../../components/organisms/profile/profile.js')
require('../../components/organisms/related-objects/related-objects.js')
require('../../components/organisms/search-header/search-header.js')
require('../../components/organisms/search-results/search-results.js')
require('../../components/structures/cookie-bar/cookie-bar.js')
require('../../components/structures/grid-overlay/grid-overlay.js')
require('../../components/structures/menu-bar/menu-bar.js')
require('../../components/structures/menu-overlay/menu-overlay.js')
require('../../components/structures/skip-to-main/skip-to-main.js')

// There are 4 load events:
// - 'init-immediate': (compiles when the script is loaded; blocks rendering)
// - 'init-load': (on DOMContentLoaded event; does not block rendering)
// - 'init-after-load': (on Load event, slightly after DOMContentLoaded)
// - 'init-delayed-load': (after Load event, with a slight delay, for iframes and such)
// Usually, the 'init-load' event will suffice.
window.dispatchEvent(new CustomEvent('init-immediate'))
window.addEventListener('DOMContentLoaded', () => window.dispatchEvent(new CustomEvent('init-load')))
window.addEventListener('load', () => window.dispatchEvent(new CustomEvent('init-after-load')))
window.addEventListener('load', () => window.setTimeout(() => window.dispatchEvent(new CustomEvent('init-delayed-load')), 500))

// Focus stuff
document.addEventListener('keydown', () => document.documentElement.classList.add('key-pressed'))
document.addEventListener('mousedown', () => document.documentElement.classList.remove('key-pressed'))
document.addEventListener('touchstart', () => document.documentElement.classList.remove('key-pressed'))

// loading="lazy" polyfill (~3kB) - for non-Chrome
if (!('loading' in HTMLImageElement.prototype)) {
  require('loading-attribute-polyfill')
}

// Import smooth scroll (~35kB) - for Safari and Edge
if (!('scrollBehavior' in document.documentElement.style)) {
  const smoothscroll = require('smoothscroll-polyfill')
  smoothscroll.polyfill()
}

if (window.navigator.userAgent.indexOf('Safari') !== -1) {
  // Web animations polyfill ~(50kB) - for Safari and Edge
  // About 50kb raw, so only use if you need it.
  // const x = import('web-animations-js')
}

console.log('\n %cMade with %c♥%c by Fabrique \n', 'font: 16px serif;', 'font: 13px serif; color: #f00;', 'font: 16px serif;')

const resizeHandlerVH = () => {
  const styleRoot = document.documentElement.style
  const width = document.documentElement.clientWidth
  const height = document.documentElement.clientHeight
  styleRoot.setProperty('--real-100vw', `${width}px`)
  styleRoot.setProperty('--real-1vw', `${width / 100}px`)
  styleRoot.setProperty('--real-100vh', `${height}px`)
  styleRoot.setProperty('--real-1vh', `${height / 100}px`)
}
window.addEventListener('resize', () => resizeHandlerVH(), { passive: true })
resizeHandlerVH()
